import React, { PureComponent } from 'react';
import { Constant } from '../../../../../utils';
import { ButtonColors, Button, Input, PopupWrapper } from '../../../../components';
import './creationPopup.scss';

export class CreationPopup extends PureComponent {

  state = {
    firstName: null,
    lastName: null,
    email: null,
    firstNameInvalid: false,
    lastNameInvalid: false,
    emailInvalid: false,
    loginTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.loginTriggered ? isInvalid ? 'invalid' : 'valid' : '';
  };

  handleKeyPressed = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const {firstName, lastName, email} = this.state;
      this.handleStartInscription(firstName, lastName, email);
    }
  };

  handleStartInscription = (firstName, lastName, email) => {
    const {action} = this.props;
    const firstNameInvalid = !firstName || firstName.length === 0;
    const lastNameInvalid = !lastName || lastName.length === 0;
    const emailInvalid = !email || email.length === 0 || !Constant.REGEX.EMAIL.test(email);

    this.setState({
      loginTriggered: true,
      firstNameInvalid,
      lastNameInvalid,
      emailInvalid,
    });

    if (!firstNameInvalid && !lastNameInvalid && !emailInvalid) {
      action(firstName, lastName, email);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({[tag]: value});
  };

  resetValidator = () => {
    if (this.state.loginTriggered) {
      this.setState({
        firstNameInvalid: false,
        lastNameInvalid: false,
        emailInvalid: false,
        loginTriggered: false,
      });
    }
  };

  render() {
    const {strings, displayed, cancelAction} = this.props;
    const {firstName, lastName, email, firstNameInvalid, lastNameInvalid, emailInvalid} = this.state;

    const emailError = emailInvalid ? (!email || email.length < 1) ? strings('input_require') : strings('wrong_email') : null;
    return (
      <PopupWrapper
        id={'creationPopup'}
        className={'login-form'}
        displayed={displayed}
        cancelAction={cancelAction}>
        <form className={'form-wrapper'} onKeyPress={this.handleKeyPressed}>
          <div className={'identity'}>
            <Input
              label={strings('firstName')}
              type={'text'}
              tag={'firstName'}
              className={'firstName'}
              inputClassName={`${this.getValidityClass(firstNameInvalid)}`}
              error={firstNameInvalid ? strings('input_require') : null}
              handleValueChanged={this.handleValueChanged}
            />
            <Input
              label={strings('lastName')}
              type={'text'}
              tag={'lastName'}
              className={'lastName'}
              inputClassName={`${this.getValidityClass(lastNameInvalid)}`}
              error={lastNameInvalid ? strings('input_require') : null}
              handleValueChanged={this.handleValueChanged}
            />
          </div>
          <Input
            label={strings('email')}
            type={'email'}
            tag={'email'}
            className={'email'}
            inputClassName={`${this.getValidityClass(emailInvalid)}`}
            error={emailError}
            handleValueChanged={this.handleValueChanged}
          />
          <Button
            color={ButtonColors.green}
            label={strings('access')}
            className={'login'}
            action={() => this.handleStartInscription(firstName, lastName, email)}
          />
        </form>
      </PopupWrapper>
    );
  }
}
